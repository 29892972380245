module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"fkt0thr"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-B7PZKKGNL0","cookieName":"ravenbound-gdpr-google-analytics","anonymize":true,"allowAdFeatures":false},"googleTagManager":{"trackingId":"GTM-5P4V3ZP","cookieName":"ravenbound-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"facebookPixel":{"pixelId":"780351933390583","cookies":"ravenbound-gdpr-facebook-pixel"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"obqiq"},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
